<template>
    <div>
        <el-form label-width="120px"
                 :model="baseInfo"
                 :rules="Rules"
                 ref="form"
                 class="form"
                 size="small">
            <div class="goods-edit_region-title">{{$t("m.goods.jibenxinxi")}}</div>
            <el-form-item class="width50" v-if="!baseInfo.id">
                <template slot="label">
                    <span style="color: #F56C6C;margin-right: 4px;">*</span>{{$t("m.template.edit.wxID")}}:
                </template>
                <el-select v-model="baseInfo.miniProId"
                           filterable
                           clearable
                           :placeholder="$t('m.goods.qingxuanze')">
                    <el-option v-for="item in MiniProList"
                               :key="item.miniProId"
                               :label="item.name"
                               :value="item.miniProId">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="goods-edit_region-msg">
                <el-form-item :label="$t('m.template.edit.name')+':'"
                              class="width50"
                              prop="name">
                    <el-input v-model="baseInfo.name"
                              maxlength="50"
                              show-word-limit
                              :placeholder="$t('m.goods.pleaseInput')"/>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.rela')+':'"
                              class="width50" prop="star">
                    <el-select v-model="baseInfo.star"
                               :placeholder="$t('m.goods.qingxuanze')">
                        <el-option v-for="item in scoreList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('m.template.edit.code')+':'"
                              class="width50"
                              prop="code">
                    <el-input v-model="baseInfo.code"
                              maxlength="50"
                              show-word-limit
                              :placeholder="$t('m.goods.pleaseInput')"/>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.wxtemplateCode')+':'"
                              class="width50">
                    <el-select v-model="baseInfo.wxTemplateId"
                               clearable
                               :placeholder="$t('m.goods.qingxuanze')">
                        <el-option v-for="item in wxcodeList"
                                   :key="item.id"
                                   :label="item.version+' - '+item.desc"
                                   :value="item.templateId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.span')+':'"
                              class="width50">
                    <el-input v-model="baseInfo.describe"
                              maxlength="100"
                              show-word-limit
                              :placeholder="$t('m.goods.pleaseInput')"/>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.shoumaiNum')+':'"
                              class="width50">
                    <el-input v-model="baseInfo.salesCount"
                              type="Number"
                              :placeholder="$t('m.goods.pleaseInput')"/>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.isBlank')+':'" class="width50" prop="isBlank">
                    <el-radio-group v-model="baseInfo.isBlank">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.basePrice')+':'" class="width50" prop="basePrice">
                    <el-input v-model="baseInfo.basePrice"
                              type="Number"
                              :placeholder="$t('m.goods.pleaseInput')"/>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.hot')+':'"
                              class="width50"
                              prop="introduce">
                    <el-input v-model="baseInfo.introduce"
                              maxlength="200"
                              type="textarea"
                              show-word-limit
                              :placeholder="$t('m.goods.pleaseInput')"/>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.saleState')+':'"
                              class="width50" prop="status">
                    <el-select v-model="baseInfo.status"
                               :placeholder="$t('m.goods.qingxuanze')">
                        <el-option v-for="item in BreadMenu"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.group')+':'"
                              class="width50"
                              prop="industryId">
                    <el-select v-model="baseInfo.industryId"
                               :placeholder="$t('m.goods.qingxuanze')">
                        <el-option v-for="item in Groups"
                                   :key="item.id"
                                   :label="item.industryName"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('m.template.edit.zhichipintai')+':'"
                              class="width50">
                    <el-checkbox v-model="baseInfo.wxStatus" false-label="0" true-label="1">微信</el-checkbox>
                    <el-checkbox v-model="baseInfo.baiduStatus" false-label="0" true-label="1">百度</el-checkbox>
                    <el-checkbox v-model="baseInfo.alipayStatus" false-label="0" true-label="1">支付宝</el-checkbox>
                    <el-checkbox v-model="baseInfo.douyinStatus" false-label="0" true-label="1">抖音</el-checkbox>
                </el-form-item>
            </div>

            <el-form-item :label="$t('m.template.edit.fengmian')+':'"
                          class="width50 shangpinImgs"
                          prop="coverUrl">
                <el-row type="flex"
                        class="editor_goods_form_control-content ">
                    <div class="editor_goods_upload_box hover" v-if="baseInfo.coverUrl">
                        <el-image :src="imageUrl(baseInfo.coverUrl)" class="img"
                                  :preview-src-list="[imageUrl(baseInfo.coverUrl)]" fit="contain"
                                  style="margin-right: 10px;"></el-image>
                        <div class="images-delete">
                            <i class="el-icon-delete" @click="delCoverUrl"/>
                        </div>
                    </div>
                    <el-upload
                            :show-file-list="false"
                            :on-success="coverSuccess"
                            :on-error="coverError"
                            :headers="headers"
                            accept="image/png,image/jpeg"
                            :action="this.$baseUrl + 'template/admin/upload'">
                        <div class="form-value">
                            <div class="editor_goods_upload_box">+ {{$t("m.goods.tianjiaimg")}}</div>
                        </div>
                    </el-upload>
                </el-row>
            </el-form-item>
            <el-form-item :label="$t('m.template.edit.yulantu')+':'"
                          class="width50 shangpinImgs" prop="thumbUrlImg">
                <el-row type="flex"
                        class="editor_goods_form_control-content ">
                    <div v-if="baseInfo.thumbUrlImgs&&baseInfo.thumbUrlImgs.length>0">
                        <draggable :list="baseInfo.thumbUrlImgs" :sort="true" class="goods-imgs" animation="500">
                            <div class="editor_goods_upload_box hover" v-for="(item,index) in baseInfo.thumbUrlImgs" :key="index">
                                <el-image :src="item" class="img"
                                          :preview-src-list="baseInfo.thumbUrlImgs" fit="contain"
                                          style="margin-right: 10px;"></el-image>
                                <div class="images-delete">
                                    <i class="el-icon-delete" @click="delthumbUrl(index)"/>
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <el-upload
                            :show-file-list="false"
                            :on-success="thumbSuccess"
                            :on-error="coverError"
                            :headers="headers"
                            accept="image/png,image/jpeg"
                            :action="this.$baseUrl + 'template/admin/upload'">
                        <div class="form-value">
                            <div class="editor_goods_upload_box">+ {{$t("m.goods.tianjiaimg")}}</div>
                        </div>
                    </el-upload>
                </el-row>
            </el-form-item>
            <el-form-item :label="$t('m.template.edit.jieshaotu')+':'"
                          class="width50 shangpinImgs" prop="contentUrlImg">
                <el-row type="flex"
                        class="editor_goods_form_control-content ">
                    <div v-if="baseInfo.contentUrlImgs&&baseInfo.contentUrlImgs.length>0">
                        <draggable :list="baseInfo.contentUrlImgs" :sort="true" class="goods-imgs" animation="500">
                            <div class="editor_goods_upload_box hover" v-for="(item,index) in baseInfo.contentUrlImgs" :key="index">
                                <el-image :src="item" class="img"
                                          :preview-src-list="baseInfo.contentUrlImgs" fit="contain"
                                          style="margin-right: 10px;"></el-image>
                                <div class="images-delete">
                                    <i class="el-icon-delete" @click="delcontentUrl(index)"/>
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <el-upload
                            :show-file-list="false"
                            :on-success="contentSuccess"
                            :on-error="coverError"
                            :headers="headers"
                            accept="image/png,image/jpeg"
                            :action="this.$baseUrl + 'template/admin/upload'">
                        <div class="form-value">
                            <div class="editor_goods_upload_box">+ {{$t("m.goods.tianjiaimg")}}</div>
                        </div>
                    </el-upload>
                </el-row>
            </el-form-item>
            <div class="goods-edit_region-title">{{$t('m.goods.jiagekucun')}}</div>
            <div class="goods-edit_region-content">
                <el-form-item>
                    <template slot="label">
                        <span style="color: #F56C6C;margin-right: 4px;">*</span>{{$t("m.template.edit.mubanguige")}}:
                    </template>
                    <editorTable :tableTitle="$t('m.template.edit.banben')"
                                 :tableData="editionData"
                                 :columns="editionColumns"
                                 @blur="editionBlur($event,'edition')"
                                 @add="addEdition"
                                 @del="delEdition"></editorTable>
                    <editorTable :tableTitle="$t('m.template.edit.duration')"
                                 :tableData="durationData"
                                 :columns="durationColumns"
                                 @blur="editionBlur($event,'duration')"
                                 @add="addDuration"
                                 @del="delDuration"
                                 @sortChange="sortChange($event,'duration')"
                    ></editorTable>
                    <editorTable :tableTitle="$t('m.template.edit.price')"
                                 :tableData="priceData"
                                 :columns="priceColumns"
                    ></editorTable>
                </el-form-item>

            </div>

        </el-form>
    </div>

</template>

<script>

    import {
        getIndustries,
        getTemplateOptionList,
        getMiniProOptionList,
    } from '../../../../request/api/template'
    import draggable from '@/components/common/cz-draggable/vuedraggable'
    import {getImageUrl} from "../../../../utils/ImageUtils";
    import editorTable from "./editor-table";
    import CommonLogin from "../../../../utils/CommonLogin";


    export default {
        components: {
            editorTable,
            draggable,
        },
        props: {
            baseInfo:Object,
            //版本
            editionData:{
                type:Array,
                default:[{}]
            },
            //时长
            durationData:{
                type:Array,
                default:[{}]
            },
            //价格
            priceData:Array
        },
        data() {
            return {
                loading:false,
                headers:{},
                //版本
                editionColumns:{
                    menu:true,
                    width:"150px",
                    columns:[
                        {
                            prop:"name",
                            label:"版本名称",
                            formType:'input',
                        },{
                            prop:"describe",
                            label:"简述",
                            formType:'textarea',
                        },{
                            prop:"price",
                            label:"月单价(元)",
                            formType:'input',
                        },{
                            prop:"showPrice",
                            label:"展示月单价",
                            formType:'input',
                        },{
                            prop:"unit",
                            label:"单位",
                            formType:'select',
                            formList:[
                                {
                                    id:"天",
                                    name:"天"
                                },{
                                    id:"年",
                                    name:"年"
                                },{
                                    id:"月",
                                    name:"月"
                                },
                            ],
                        },{
                            prop:"maxPage",
                            label:"页面上限",
                            formType:'number',
                        },{
                            prop:"maxOrder",
                            label:"每日订单上限",
                            formType:'number',
                        },{
                            prop:"maxGoods",
                            label:"商品上限",
                            formType:'number',
                        },{
                            prop:"maxZone",
                            label:"空间上限(G)",
                            formType:'number',
                        },{
                            prop:"isBusiness",
                            label:"是否支持商用",
                            formType:'radio',
                            formList:[
                                {
                                    id:0,
                                    name:"不支持"
                                },
                                {
                                    id:1,
                                    name:"支持"
                                }
                            ],
                        },{
                            prop:"order",
                            label:"序号",
                            formType:'inputNumber',
                        }
                    ]
                },
                //时长
                durationColumns:{
                    menu:true,
                    columns:[
                        {
                            prop:"name",
                            label:"时长名称",
                            formType:'input',
                        }, {
                            prop:"duration",
                            label:"时长",
                            formType:'number',
                        }, {
                            prop:"discount",
                            label:"折扣",
                            formType:'number',
                        }, {
                            prop:"discountName",
                            label:"折扣名称",
                            formType:'input',
                        },
                        {
                            prop:"order",
                            label:"序号",
                            formType:'inputNumber',
                        },
                    ]
                },
                //价格
                priceColumns:{
                    menu:false,
                    columns:[
                        {
                            prop:"versionName",
                            label:"版本",//versionId
                            formType:'text',
                        }, {
                            prop:"durationName",
                            label:"时长",//durationId
                            formType:'text',

                        }, {
                            prop:"price",
                            label:"价格（元）",
                            formType:'number',
                        },
                    ]
                },
                Rules: {
                    //模板名称
                    name: [
                        {required: true, message: this.$t("m.goods.qingshurushangpinmingcheng"), trigger: 'blur'},
                        {min: 1, max: 50, message: this.$t("m.goods.changduzai1dao50gezifu"), trigger: 'blur'}
                    ],
                    //模板编码
                    code: [
                        {required: true, message: this.$t("m.goods.qingshurushangpinbianma"), trigger: 'blur'},
                        {min: 1, max: 50, message: this.$t("m.goods.changduzai1dao50gezifu"), trigger: 'blur'}
                    ],
                    //模板星级
                    star:[
                        {required: true, message: '请选择模板星级', trigger: 'change'},
                    ],
                    salesCount:[
                        {required: true, message: '请输入售卖数量', trigger: 'blur'},
                    ],
                    introduce: [
                        {required: true, message: '请输入模板卖点', trigger: 'blur'},
                    ],
                    status:[
                        {required: true, message: '请选择售卖状态', trigger: 'change'},
                    ],
                    industryId: [
                        {required: true, message: '请选择模板分组', trigger: 'change'}
                    ],
                    coverUrl:[
                        {type: 'string',required: true, message: '请上传模板封面', trigger: 'change'}
                    ],
                    thumbUrlImg: [
                        {type: 'array', required: true, message: '请上传模板预览图', trigger: 'change'}
                    ],
                    contentUrlImg: [
                        {type: 'array', required: true, message: '请上传模板介绍图', trigger: 'change'}
                    ],
                    isBlank:[
                        {required: true, message: '请选择是否为空白模板', trigger: 'change'}
                    ],
                    basePrice:[
                        {required: true, message: '请输入模板价格', trigger: 'blur'},
                    ],
                },//表单验证

                Groups: [],//商品分组
                //售卖状态
                BreadMenu: [{
                    id:0,
                    name:'在售'
                },{
                    id:1,
                    name:'下架'
                }],
                //模板星级
                scoreList:[
                    {
                        id:1,
                        name:"一星"
                    },{
                        id:2,
                        name:"二星"
                    },{
                        id:3,
                        name:"三星"
                    },{
                        id:4,
                        name:"四星"
                    },{
                        id:5,
                        name:"五星"
                    },
                ],
            //    支持平台
                platformList:[
                    {
                        id:0,
                        name:"微信"
                    },{
                        id:1,
                        name:"百度"
                    },{
                        id:2,
                        name:"支付宝"
                    },{
                        id:3,
                        name:"抖音"
                    },
                ],
                //微信模板编码
                wxcodeList:[],
            //    小程序id
                MiniProList:[],
            }
        },
        mounted() {
            this.findProductGroup();
            this.getTemplateOptions();
            let token = CommonLogin.getToken()
            this.headers = {
                'Authorization': 'bearer ' + token,
                'miniProId':this.$store.state.miniPro.miniProId,
            }
            this.getMiniProList()
;        },
        methods: {
            imageUrl(url){
                return getImageUrl(url);
            },
            //查询商品分组
            findProductGroup() {
                getIndustries().then(res => {
                    if (res.code == 200) {
                        this.Groups = res.data
                    }
                });
            },
            //获取微信模板编码
            getTemplateOptions(){
                getTemplateOptionList().then(res=>{
                    if(res.code==200){
                        this.wxcodeList = res.data
                    }
                })
            },
            //获取小程序列表
            getMiniProList(){
                getMiniProOptionList().then(res=>{
                    if(res.code==200){
                        this.MiniProList = res.data
                    }
                })
            },
            //上传封面
            coverSuccess(resp){
                if (resp.code !== 200) {
                    this.$MESSAGE(resp);
                    return;
                }
                this.baseInfo.coverUrl = resp.data.url;
                this.$forceUpdate()
            },
            coverError(){
                let _this = this;
                this.baseInfo.coverUrl = ''
                this.$message.error(_this.$t('manager.selfInformation.uploadFailed'));
            },
            delCoverUrl(){
                this.baseInfo.coverUrl = ''
                this.$forceUpdate()
            },
            //预览图
            thumbSuccess(resp){
                if (resp.code !== 200) {
                    this.$MESSAGE(resp);
                    return;
                }
                this.baseInfo.thumbUrlImg.push(resp.data.url)
                this.baseInfo.thumbUrlImgs.push(this.$imageUrl + resp.data.url)
                this.$forceUpdate()
            },
            delthumbUrl(index){
                this.baseInfo.thumbUrlImg.splice(index,1)
                this.baseInfo.thumbUrlImgs.splice(index,1)
                this.$forceUpdate()
            },
            //介绍图
            contentSuccess(resp){
                if (resp.code !== 200) {
                    this.$MESSAGE(resp);
                    return;
                }
                console.log(resp)
                this.baseInfo.contentUrlImg.push(resp.data.url)
                this.baseInfo.contentUrlImgs.push(this.$imageUrl + resp.data.url)
                this.$forceUpdate()
            },
            delcontentUrl(index){
                console.log(this.baseInfo.contentUrlImgs)
                this.baseInfo.contentUrlImg.splice(index,1)
                this.baseInfo.contentUrlImgs.splice(index,1)
                this.$forceUpdate()

            },
            //验证表单
            validate(fun){
                this.$refs.form.validate(fun);
            },
        //    版本操作
            editionBlur(row,type){
              // console.log(508,row)
                row.type = type
                this.$emit('editionBlur',row)
            },
            addEdition(row){
                this.$emit('addEdition',row)
            },
            delEdition(row){
                this.$emit('delEdition',row)
            },
        //    设置排序
            sortChange(row,type){
                row.type = type
                this.$forceUpdate()
                this.$emit('sortChange',row)
            },
        //    时长操作
            addDuration(row){
                this.$emit('addDuration',row)

            },
            delDuration(row){
                this.$emit('delDuration',row)

            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index";

    .form{
        background: white;
        padding: 0 20px 20px;
    }
    .width50 {
        width: 50%;
    }

    .goods-imgs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .shangpinImgs /deep/ .el-form-item__content{
        width: 100%!important;
    }
    .editor_goods_upload_box {
        position: relative;
        flex-shrink: 0;
    }
    .editor_goods_form_control-content{
        position: relative;
    }
    .images-delete {
        width: 82px;
        height: 82px;
        line-height: 80px;
        color: white;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
    }

    .editor_goods_upload_box.hover:hover .images-delete {
        display: block;
        z-index: 10;
    }

    .goods-edit_region-title {
        background-color: #f8f8f8;
        font-size: 14px;
        font-weight: 700;
        padding: 10px;
        margin-bottom: 20px;
    }

    .goods-edit_region-content {
        padding: 20px 0 10px;
    }
    .goods-edit_region-msg{
        display: flex;
        flex-wrap: wrap;
    }
    .editor_goods_upload_box {
        display: inline-block;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
        color: @color-primary;
        margin: 0 10px 10px 0;
        width: 80px;
        height: 80px;
        border: 1px solid #ddd;
        background-color: #fff;
        font-size: 12px;
        background-repeat: no-repeat;
    }

    .line-height-36 {
        line-height: 36px;
    }
    /deep/ .el-select{
        width: 100%;
    }
    .img{
        width: 82px;
        height: 82px;
        background: #f3f3f3;
    }
</style>
