<template>
    <div class="wepper eltable">
        <div class="tableHeader">{{tableTitle}}</div>
        <el-table
                ref="filterTable"
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    v-for="(item,index) in columns.columns"
                    :key="index"
                    :width="columns.width"
                    align="center">
                <template slot="header" slot-scope="scope">
                    <span style="white-space: nowrap">{{item.label}}</span>
                </template>
                <template slot-scope="scope">
                    <el-input v-model="scope.row[item.prop]" v-if="item.formType=='input'" size="small" @blur="inputblur(scope.row)"></el-input>
                    <el-input type="textarea" v-model="scope.row[item.prop]" v-if="item.formType=='textarea'" size="small"></el-input>
                    <el-input type="Number" min="0" v-model="scope.row[item.prop]" v-if="item.formType=='number'" size="small" @blur="inputblur(scope.row)"></el-input>
                    <el-radio-group v-model="scope.row[item.prop]" v-if="item.formType=='radio'" size="small">
                        <el-radio v-for="(child,index) in item.formList" :key="index" :label="child.id">{{child.name}}</el-radio>
                    </el-radio-group>
                    <el-select v-model="scope.row[item.prop]" v-if="item.formType=='select'" size="small">
                        <el-option v-for="(child,index) in item.formList" :key="index" :label="child.name" :value="child.id"></el-option>
                    </el-select>
                    <el-input-number v-if="item.formType=='inputNumber'" v-model="scope.row[item.prop]"
                                     size="small" @change="sortChange(scope.row)" :min="0" ></el-input-number>
                    <span v-if="item.formType=='text'">{{scope.row[item.prop]}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    v-if="columns.menu"
                    align="center"
                    fixed="right"
                    width="100">
                <template slot="header" slot-scope="scope">
<!--                    <span>操作</span>-->
                    <el-button style="cursor: pointer" @click="handleAdd(scope.row,scope.$index)" type="text" size="small">添加</el-button>
                </template>
                <template slot-scope="scope">
                    <el-button style="cursor: pointer" type="text" size="small" @click="handleDel(scope.row,scope.$index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "editor-table",
        props:{
            tableTitle:String,
            tableData:Array,
            columns:Object,
        },
        data(){
            return{

            }
        },
        methods:{
            handleAdd(row,index){
                this.$emit('add',row)
            },
            handleDel(row,index){
                this.$emit('del',row)
            },
            inputblur(row){
                this.$emit('blur',row)
            },
            sortChange(row){
                this.$emit('sortChange',row)
            },
        }
    }
</script>

<style scoped>
    .wepper{
        margin-bottom: 20px;
    }
.tableHeader{
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 700;
    background: #f5f5f5;
    border-bottom: 1px solid #ebeef5;
}
    /deep/ .el-radio-group{
        display: flex;
        flex-wrap: wrap;
    }
</style>