<template>
    <div class="container" v-loading="loading">
        <el-row type="flex" class="goods-edit__steps">
            <el-row type="flex"
                    justify="end"
                    class="steps-confirm">
                <el-button @click="submitProduct()"
                           type="primary">{{$t("m.pubilc.save")}}</el-button>
            </el-row>
        </el-row>
        <EditBase ref="baseInfo"
                  :baseInfo="FormData.template"
                  :editionData="editionData"
                  :durationData="durationData"
                  :priceData="priceData"
                  @addEdition="addEdition"
                  @delEdition="delEdition"
                  @editionBlur="editionBlur"
                  @addDuration="addDuration"
                  @delDuration="delDuration"
                  @sortChange="sortChange"/>

    </div>
</template>

<script>
    const Default_Buy_title = "立即购买";//默认购买的按钮显示文字
    import EditBase from "./edit/editor-base"
    import {getTemplateInfo,saveTemplateInfo} from "request/api/template"

    export default {
        components: {
            EditBase,
        },

        data() {
            return {
                FormData: {
                    template: {
                        // id:'',//id
                        name: '',//名称
                        code: '',//编码
                        describe: '',//简介
                        feature: '',//卖点
                        images: [],//图片
                        industryId: '',//所属行业
                        introduce:'',//模板介绍
                        introduceImg:[],
                        basePrice: 0,//价格
                        salesCount:0,//销售数量
                        star:'',//星级评价,
                        status:'',//售卖状态,
                        thumbUrl:'',//预览图,
                        thumbUrlImg:[],//预览图数组
                        thumbUrlImgs:[],
                        contentUrl:'',//介绍图
                        contentUrlImg:[],//介绍图数组
                        contentUrlImgs:[],
                        wxTemplateId:'',//微信代码模板id
                        miniProId:'',
                    }
                },  //表单数据
                editionData:[],
                durationData:[],
                priceData:[],
                loading:false,
            }
        },

        mounted() {
            if (this.$route.query.id){
                this.FormData.template.id = this.$route.query.id;
                this.getInfo();
            }
        },
        methods: {
            //鼠标移除
            editionBlur(row){
                if(this.priceData.length>0){
                    this.priceData.forEach(item=>{
                        if(row.type=='edition'&&(item.versionId==row.id)){
                            item.versionName = row.name
                        }
                        if(row.type=='duration'&&item.durationId==row.id){
                            item.durationName = row.name
                        }
                    })
                    this.priceData = [...this.priceData]
                    this.$forceUpdate()
                }
            },
            //版本
            addEdition(row){
                let addId = this.editionData.length+1
                this.editionData.push({
                    tempVersionId:addId
                })
                if(this.durationData.length > 0){
                    this.durationData.forEach(item => {
                        let price = {
                            versionName: "",
                            durationName:item.name,
                            tempVersionId:addId,
                            price:"",
                        };
                        // during.id存在，保存durationId，否则保存tempDurationId
                        if (item.id) {
                            price.durationId = item.id;
                        } else {
                            price.tempDurationId = item.tempDurationId;
                        }

                        this.priceData.push(price)
                    })
                }
            },
            delEdition(row){
                // if(this.editionData.length==1){
                //     this.$message({
                //         type: 'info',
                //         message: '至少保留一条数据'
                //     });
                // }else {
                    let _this = this
                    this.$confirm('确定删除?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let priceData = _this.priceData
                        let newData = []
                        if(priceData.length>0){
                            priceData.forEach(item=>{
                                if(item.versionId!=row.id){
                                    newData.push(item)
                                }
                            })
                        }
                        _this.priceData = [...newData]
                        if(_this.editionData.length>0){
                            _this.editionData.forEach((item,index)=>{
                                if(item.id==row.id){
                                    _this.editionData.splice(index,1)
                                }
                            })
                        }
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                // }
            },
            //排序
            sortChange(row){
                // if(row.type=='duration'){
                //     if(this.durationData.length>0){
                //         this.durationData.forEach(item=>{
                //             if(item.id == row.id){
                //                 item.order=row.order
                //                 this.$forceUpdate()
                //             }
                //         })
                //     }
                //     this.durationData = [...this.durationData]
                //     this.durationData.sort((a,b)=>{ return a.age-b.age})
                // }
            },
            //时长
            addDuration(row){
                let addId = this.durationData.length + 1
                this.durationData.push({
                    tempDurationId:addId
                })
                if(this.editionData.length > 0){
                    this.editionData.forEach(item => {
                        let price = {
                            versionName: item.name,
                            durationName: "",
                            versionId: item.id,
                            tempDurationId: addId,
                            price: "",
                        };
                        // version.id存在，保存versionId，否则保存tempVersionId
                        if (item.id) {
                            price.versionId = item.id;
                        } else {
                            price.tempVersionId = item.tempVersionId;
                        }
                        this.priceData.push(price);
                    })
                }
            },
            delDuration(row){
                // if(this.durationData.length==1){
                //     this.$message({
                //         type: 'info',
                //         message: '至少保留一条数据'
                //     });
                // }else{
                    let _this = this
                    this.$confirm('确定删除?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let priceData = _this.priceData
                        let newData = []
                        if(priceData.length>0){
                            priceData.forEach(item=>{
                                if(item.durationId!=row.id){
                                    newData.push(item)
                                }
                            })
                        }
                        _this.priceData = [...newData]
                        if(_this.durationData.length>0){
                            _this.durationData.forEach((item,index)=>{
                                if(item.id==row.id){
                                    _this.durationData.splice(index,1)
                                }
                            })
                        }
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                // }

            },
            //保存操作
            submitProduct() {
                let _this = this;
                this.$refs.baseInfo.validate((valid) => {
                    if (valid) {
                        if(_this.editionData.length==0||_this.durationData.length==0||_this.priceData.length==0){
                            _this.$message.warning('请输入模板规格')
                            return
                        }
                        _this.save();
                    } else {
                        return false;
                    }
                });
            },

            //保存到后台
            save(){
                this.loading = true
                let _this = this;
                let param = {
                    miniProId:this.FormData.template.miniProId,
                    template:{
                        isBlank:this.FormData.template.isBlank,
                        basePrice:this.FormData.template.basePrice,
                        alipayStatus:this.FormData.template.alipayStatus,//支付宝
                        baiduStatus:this.FormData.template.baiduStatus,//百度
                        code:this.FormData.template.code,//模板编码
                        contentUrl:this.FormData.template.contentUrlImg.join("|"),//内容图片
                        coverUrl:this.FormData.template.coverUrl,//封面图片
                        describe:this.FormData.template.describe,//标签
                        douyinStatus:this.FormData.template.douyinStatus,//抖音
                        industryId:this.FormData.template.industryId,//所属行业
                        introduce:this.FormData.template.introduce,//卖点
                        name:this.FormData.template.name,//模板名称
                        salesCount:this.FormData.template.salesCount,//销售数量
                        star:this.FormData.template.star,//星级评价 [0-5]星
                        status:this.FormData.template.status,//0:正常使用，1下架
                        thumbUrl:this.FormData.template.thumbUrlImg.join("|"),//预览图
                        wxStatus:this.FormData.template.wxStatus,//微信
                        wxTemplateId:this.FormData.template.wxTemplateId,//微信代码模板id
                    },
                }

                if(this.FormData.template.id){
                    param.type = "edit"
                    param.template.id = this.FormData.template.id
                }else{
                    param.type = "add"
                }
                param.templatePrice = this.priceData
                param.templatePriceVersion = this.editionData
                param.templatePriceDuration = this.durationData

                saveTemplateInfo(param).then(res => {
                    _this.loading = false
                    // console.log(241,res)
                    _this.$MESSAGE(res,()=>{
                        _this.$router.go(-1);
                    });
                })
            },
            //数据去重
            unique(arr){
                for(var i=0; i<arr.length; i++){
                    for(var j=i+1; j<arr.length; j++){
                        if(arr[i].id==arr[j].id){         //第一个等同于第二个，splice方法删除第二个
                            arr.splice(j,1);
                            j--;
                        }
                    }
                }
                return arr;
            },

            //查询
            getInfo(){
                this.loading = true
                let data = {
                    templateId:this.FormData.template.id
                };
                let _this = this;
                this.editionData = []
                this.durationData = []
                this.priceData = []
                getTemplateInfo(data).then(res=>{
                    _this.loading = false
                    if(res.code === 200){
                        _this.FormData = res.data
                        _this.FormData.template.contentUrlImg = res.data.template.contentUrl.split("|")
                        _this.FormData.template.contentUrlImgs = []
                        if(_this.FormData.template.contentUrlImg.length>0){
                            _this.FormData.template.contentUrlImg.forEach(item=>{
                                _this.FormData.template.contentUrlImgs.push(this.$imageUrl+item)
                            })
                        }
                        _this.FormData.template.thumbUrlImg = res.data.template.thumbUrl.split("|")
                        _this.FormData.template.thumbUrlImgs = []
                        if(_this.FormData.template.thumbUrlImg.length>0){
                            _this.FormData.template.thumbUrlImg.forEach(item=>{
                                _this.FormData.template.thumbUrlImgs.push(this.$imageUrl+item)
                            })
                        }
                        console.log(305,_this.FormData.template)
                        _this.editionData = res.data.info.templatePriceVersion
                        _this.durationData = res.data.info.templatePriceDuration
                        _this.priceData = res.data.info.templatePrice
                        if(_this.priceData.length>0){
                            _this.priceData.forEach(item=>{
                                _this.editionData.forEach(child=>{
                                    if(item.versionId==child.id){
                                        item.versionName = child.name

                                    }
                                })
                            })
                            _this.priceData.forEach(item=>{
                                _this.durationData.forEach(child=>{
                                    if(item.durationId==child.id){
                                        item.durationName = child.name
                                    }
                                })
                            })
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .goods-edit__steps {
        width: 100%;
        background-color: white;
        padding: 10px;
    }

    .steps-item {
        display: inline-block;
        width: 40%;
    }

    .goods-steps-step {
        height: 44px;
        flex-shrink: 0;
        line-height: 44px;
        font-size: 14px;
        background: #f8f8f8;
        color: #666;
        text-align: center;
        padding: 0 10px;
        position: relative;
    }

    .goods-steps-step.active {
        background: @color-primary;
        color: #fff;
    }

    .goods-steps-step.active::after {
        border-left-color: @color-primary;
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        border-left: 12px solid @color-primary;
        position: absolute;
        top: 50%;
        margin-top: -22px;
        right: -12px;
        z-index: 1;
    }

    .steps-confirm {
        align-items: center;
        flex: 1;
    }


</style>
